@import url('https://fonts.googleapis.com/css2?family=Sarabun:wght@400&display=swap');
@media print {
  .print-area {
    font-family: 'Sarabun', sans-serif;
    box-shadow: 0px 0px;
  }

  .table-text {
    font-weight: 400;
    font-size: 12px;
  }

  header,
  footer,
  aside,
  nav,
  form,
  iframe,
  .menu,
  .hero,
  .adslot {
    display: none;
  }

  .print-disable {
    display: none;
  }
  .print-only {
    display: initial;
  }
}
